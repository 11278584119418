<template>
  <article>
    <hgroup><h1>기타 예제</h1></hgroup>
    <div>
      <div style="margin-top: 26px;">
        기타 예제 PoC 앱 데모영상
      </div>
    </div>
    <video src="/assets/img/damda.mp4" autoplay muted></video>
  </article>
</template>
<script>
import getStartedPage2Py from "raw-loader!@/assets/code/get-started-page2.py";
import getStartedPage2Sh from "raw-loader!@/assets/code/get-started-page2.sh";
import CodeViewer from "@/components/CodeViewer.vue";
export default {
  data() {
    return {
      getStartedPage2Py,
      getStartedPage2Sh,
    };
  },
  components: {
    CodeViewer,
  },
};
</script>
